<template>
    <button
        v-if="parentParticipant"
        type="button"
        class="flex items-center max-w-full space-x-2 w-full p-4 text-left rounded-md hover:bg-gray-200/50 transition-colors"
        :class="{ 'bg-white': isUnread }"
    >
        <UiAvatar
            class="shrink-0 size-10"
            :path="parentParticipant.user.avatar_path"
        />
        <div class="grow flex flex-col min-w-0">
            <div
                class="mb-1 text-sm"
                :class="{
                    'font-bold text-gray-900': isUnread,
                    'font-medium text-gray-800': !isUnread
                }"
            >
                {{ parentParticipant.user.first_name }} {{ parentParticipant.user.last_name }}
            </div>
            <div class="text-xs text-gray-600 flex max-w-full">
                <div
                    class="min-w-0 truncate"
                    :class="{
                        'font-semibold': isUnread
                    }"
                >
                    {{ thread.latest_message.body }}
                </div>
                <div class="shrink-0 ml-1">
                    &middot;
                    {{ $filters.ago(thread.latest_message.created_at, true) }}
                </div>
            </div>
        </div>
        <div
            v-if="isUnread"
            class="flex w-3 shrink-0 items-center"
        >
            <span class="size-3 bg-primary-500 rounded-full" />
        </div>
    </button>
</template>

<script>
export default {
    props: {
        thread: {
            type: Object,
            required: true
        }
    },

    data () {
        return {
            isUnread: this.thread.is_unread
        }
    },

    computed: {
        parentParticipant () {
            return this.thread.participants.find((participant) => {
                return participant.user.uuid !== this.$user.uuid
                    && !participant.user.current_creche_uuid
            }) || this.thread.participants.find((participant) => {
                return participant.user.uuid !== this.$user.uuid
            })
        }
    },

    mounted () {
        this.$emitter.on('chat:read', (threadId) => {
            if (this.thread.id === threadId) {
                this.markAsRead()
            }
        })
    },

    methods: {
        markAsRead () {
            this.isUnread = false
        }
    }
}
</script>
